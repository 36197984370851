import React, { useState } from "react"
import { TabContent } from "./TabContent"
import { css } from "styled-components"

const TravelDestinations = ({ data }) => {
  const [active, setActive] = useState(
    data.genPagesOrNot.page.seoComponent.seoComponentServices[0]
  )

  const [activeMobile, setActiveMobile] = useState(null)

  const onMobileClick = serviceTabTitle => {
    setActiveMobile(activeMobile !== serviceTabTitle ? serviceTabTitle : null)
  }

  return (
    <div
      css={css`
        background-color: #232323;
        box-sizing: border-box;
        padding: 20px 0;
        @media (min-width: 992px) {
          padding: 20px 0 80px 0;
        }
      `}
    >
      <div
        css={css`
          max-width: 1500px;
          width: 90%;
          margin: 0 auto;
        `}
      >
        <div
          css={css`
            margin: 0 auto;
            display: flex;
            align-items: center;
            flex-direction: column;
          `}
        >
          <h2
            css={css`
              margin-top: 4rem;
              font-size: 5rem;
              display: inline-block;
              text-transform: none;
              color: var(--green);
              font-family: "Great Vibes", cursive;
              font-style: normal;
              line-height: 1;
            `}
          >
            {data.genPagesOrNot.page.seoComponent.seoComponentFancyHeading}
            <span
              css={css`
                margin-left: 2rem;
                color: var(--white);
                font-size: 2rem;
                font-family: "Theano Didot";
                font-weight: 400;
                text-transform: uppercase;
                display: block;
              `}
            >
              {
                data.genPagesOrNot.page.seoComponent
                  .seoComponentLessFancyHeading
              }
            </span>
          </h2>
        </div>
        <ul
          css={css`
            list-style-type: none;
            padding: 1.5em 0;
            @media (min-width: 992px) {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
            }
          `}
        >
          {data.genPagesOrNot.page.seoComponent.seoComponentServices.map(
            (serviceTabTitle, key) => {
              return (
                <li key={key}>
                  <button
                    css={css`
                      width: 100%;
                      color: ${serviceTabTitle === activeMobile
                        ? "var(--green)"
                        : "var(--white)"};
                      appearance: none;
                      background: transparent;
                      border: none;
                      border-bottom: ${serviceTabTitle === activeMobile
                        ? "1px solid var(--green)"
                        : "1px solid transparent"};
                      padding: 0.2rem 1rem;
                      text-transform: capitalize;
                      display: flex;
                      justify-content: space-between;
                      &:hover {
                        cursor: pointer;
                        color: var(--green);
                      }
                      @media (min-width: 992px) {
                        width: auto;
                        display: inline;
                        color: ${serviceTabTitle === active
                          ? "var(--green)"
                          : "var(--white)"};
                        border-bottom: ${serviceTabTitle === active
                          ? "1px solid var(--green)"
                          : "1px solid transparent"};
                      }
                    `}
                    onClick={() => {
                      setActive(serviceTabTitle)
                      onMobileClick(serviceTabTitle)
                    }}
                  >
                    <div
                      css={css`
                        font-size: 1.2rem;

                        @media (min-width: 992px) {
                          min-width: 100px;
                          font-size: 1.2rem;
                        }
                      `}
                      dangerouslySetInnerHTML={{
                        __html: serviceTabTitle.seoComponentServiceTitle,
                      }}
                    />
                    <span
                      css={css`
                        font-size: 1.25rem;
                        @media (min-width: 992px) {
                          display: none;
                        }
                      `}
                    >
                      {activeMobile === serviceTabTitle ? `+` : `-`}
                    </span>
                  </button>
                  <div
                    css={css`
                      display: ${serviceTabTitle === activeMobile
                        ? "block"
                        : "none"};
                      @media (min-width: 992px) {
                        display: none;
                      }
                    `}
                  >
                    <TabContent tabContent={activeMobile} data={data} />
                  </div>
                </li>
              )
            }
          )}
        </ul>
        <div
          css={css`
            display: none;
            @media (min-width: 992px) {
              display: block;
            }
          `}
        >
          <TabContent tabContent={active} data={data} />
        </div>
      </div>
    </div>
  )
}
export default TravelDestinations
