import React from "react"
import styled from "styled-components"
import GreenHeading from "../heading"
import device from "../device"

const Text = styled.div`
  width: 70%;
  margin: 0 auto;
  max-width: 1000px;
  margin-top: -5rem;
  ${device.small`width: var(--spread); margin-top: -3rem;`}
  ${device.large`margin-top: -10rem;`}
  .main-title {
    font-size: 2rem;
    text-align: center;
  }
  p {
    font-size: 1rem;
    margin: 1rem 0;
  }

  a {
    font-size: 1rem;
    color: var(--green);
    text-transform: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2rem 0;
    text-align: center;
  }

  h1 {
    font-size: 1.5rem;
    color: var(--grey);
  }

  h3 {
    font-size: 3rem;
    color: var(--green);
    font-family: "Great Vibes", cursive;
    font-style: normal;
    text-transform: none;
    ${device.small`font-size: 2.5rem;`}
  }

  h2 {
    font-size: 2rem;
    ${device.small`font-size: 1.5rem;`}
  }
`

const Bar = styled.div`
  margin-top: 5rem;
  padding: 5rem 0;
  background: var(--darkgrey);
  ${device.large`padding: 8rem 0;`}
`

const Content = ({ data }) => {
  const firstHeading = data.acf.fleet_heading.split(" ")[0]
  return (
    <>
      <Text>
        <h1 className="main-title">
          <GreenHeading text={firstHeading} />
          <br />
          {data.acf.fleet_heading.replace(firstHeading, "")}
        </h1>
        <div
          className="text"
          dangerouslySetInnerHTML={{
            __html: data.acf.content.replace("&nbsp;", ""),
          }}
        />
        <div className="bar"></div>
      </Text>
      <Bar />
    </>
  )
}

export default Content
