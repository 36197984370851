import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import useViewport from "../hooks/useViewport"
import device, { sizes } from "../device"
import GreenHeading from "../heading"
import starImage from "../../images/reviews/star.svg"
import Ratings from "../ratings/widget"
import ReviewsSlider from "./reviews-slider"
import "intersection-observer" // optional polyfill
import { useInView } from "react-intersection-observer"

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  align-items: center;
  .bg {
    display: block;
    grid-row: 1/-1;
    grid-column: 1/-1;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`

const Content = styled.div`
  grid-row: 1/-1;
  grid-column: 1/-1;
  margin-left: 10%;
  ${device.medium`margin: 5rem auto;`}
  ${device.small`margin: 10rem auto 10rem auto; width: var(--spread);`}
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    ${device.medium`width: 50%;`}
    ${device.small`width: 100%;`}
  }

  .heading {
    margin: 0 auto;
    font-size: 2.5rem;
    line-height: 0.8;
    ${device.medium`margin-top: 4rem;`}
    ${device.small`font-size: 2rem;`}
    .primary-heading {
      margin-left: -4rem;
      ${device.small`margin-left: -3rem;`}
    }
    .secondary-heading {
      color: var(--white);
    }
  }
  .stars {
    ${device.small`align-self: center; margin-bottom: 2rem;`}
    img {
      margin: 0 0.1rem;
      display: inline-block;
      width: 20px;
    }
  }
  .ratings-wrapper {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    .line {
      width: 100px;
      height: 1px;
      background: linear-gradient(to right, white, transparent);
      margin: 0 1rem;
      :nth-child(1) {
        background: linear-gradient(to left, white, transparent);
      }
      ${device.small`display: none;`}
    }
  }
`

const Reviews = () => {
  const isMobile = useViewport() <= sizes.small
  const [widgetRef, widgetInView] = useInView({
    triggerOnce: true,
  })

  const data = useStaticQuery(graphql`
    query {
      reviews: allWordpressWpReviews {
        edges {
          node {
            acf {
              client_reviews_text
            }
          }
        }
      }
      desktopBg: file(relativePath: { eq: "reviews/desktop-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mobileBg: file(relativePath: { eq: "reviews/mobile-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const sources = [
    data.mobileBg.childImageSharp.fluid,
    {
      ...data.desktopBg.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]

  const posts = data.reviews.edges.map(
    ({ node }) => node.acf.client_reviews_text
  )

  return (
    <Wrapper>
      <Img
        fluid={sources}
        className="bg"
        objectPosition={isMobile ? "right" : "center"}
      />
      <Content>
        <div className="content-wrapper">
          <h2 className="heading">
            <span className="primary-heading">
              <GreenHeading text="Client" />
            </span>
            <br />
            <span className="secondary-heading">Reviews</span>
          </h2>
          <ReviewsSlider posts={posts} />
          <div className="stars">
            <img src={starImage} alt="Client rating" />
            <img src={starImage} alt="Client rating" />
            <img src={starImage} alt="Client rating" />
            <img src={starImage} alt="Client rating" />
            <img src={starImage} alt="Client rating" />
          </div>
          <div className="ratings-wrapper" ref={widgetRef}>
            {/* <span className="line" /> */}
            {isMobile && widgetInView ? <Ratings /> : null}
            {/* <span className="line" /> */}
          </div>
        </div>
      </Content>
    </Wrapper>
  )
}

export default Reviews
