import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import device from "../device"
import arrow from "../../images/locations/next.svg"

const Wrapper = styled.div`
  margin: 2rem 0;
  width: 40vw;
  max-width: 600px;
  color: var(--white);
  font-size: 0.9rem;
  ${device.medium`width: 50vw;`}
  ${device.small`width: 80vw;`}
  .item {
    padding: 0 2rem;
    line-height: 1.6;
  }

  .slick-arrow {
    z-index: 100;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 30px;
      display: block;
    }
    &.slick-next {
      right: -5%;
    }
    &.slick-prev {
      left: -5%;
      transform: rotate(180deg) translateY(50%);
    }
  }
`

const Arrow = props => {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={arrow} alt="Slider Button" />
    </div>
  )
}

const ReviewsSlider = ({ posts }) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
  }
  return (
    <Wrapper>
      <Slider {...settings}>
        {posts.map((item, i) => (
          <div className="slide" key={i}>
            <div className="item">{item}</div>
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default ReviewsSlider
